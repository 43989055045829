import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import Select from 'react-select';
import { NumericFormat, NumberFormatBase } from 'react-number-format';

import MaskedInput from 'react-text-mask';
import { MdDelete, MdAdd, MdToggleOff, MdToggleOn } from 'react-icons/md';

import { useTelegram } from "../../hooks/useTelegram";

import { Input, DateInput } from '../../components/UI';
import TgHeader from '../../components/TgHeader';

import './tg.css';
import ToggleSwitch from '../../components/UI/Toggle/ToggleSwitch';
import { buildingTypes, objectOfInsuranceTypes, getObjectOfInsuranceTypeNameById, materialOptions } from '../../additional';

const punycode = require('punycode/');

const getQueryParams = (query = null) => {
    return (
        (query || window.location.search.replace("?", ""))

            // get array of KeyValue pairs
            .split("&")

            // Decode values
            .map((pair) => {
                let [key, val] = pair.split("=");

                return [key, decodeURIComponent(val || "")];
            })

            // array to object
            .reduce((result, [key, val]) => {
                result[key] = val;
                return result;
            }, {})
    );
};

const NewIpotekaForm = () => {
    const [searchParams] = useSearchParams();
    const main_menu = searchParams.get("main_menu");
    const clientId = searchParams.get("userId");

    const type = "Ипотека";
    const [error, setError] = useState(null);
    const [banks, setBanks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [gender, setGender] = useState('Мужской');//Мужской/Женский
    const [newMortgage, setNewMortgage] = useState(true);//Новая или не новая ипотека
    const [birthday, setBirthday] = useState('');
    const [dateCredit, setDateCredit] = useState('');
    const [bankBeneficiary, setBankBeneficiary] = useState(null);
    const [insuranceType, setInsuranceType] = useState({
        property: false,
        life: false,
        title: false,
        comprehensiveInsurance: false
    });
    const [summ, setSumm] = useState(0.0);
    const [showTitul, setShowTitul] = useState(true);
    const [showPopover, setShowPopover] = useState(false);
//new
    const [objectOfInsuranceTypeId, setObjectOfInsuranceTypeId] = useState(null);
    const [yearOfConstruction, setYearOfConstruction] = useState(0);
    const [floorMaterial, setFloorMaterial] = useState(null);
    const [wallMaterial, setWallMaterial] = useState(null);
    const [square, setSquare] = useState(0.0);
//end new

    const { tg } = useTelegram();

    useEffect(() => {

        if (bankBeneficiary) {
            const bankName = banks.find(x => x.id === bankBeneficiary)?.name.toUpperCase().trim();
            //console.log(bankName, bankName == "СБЕРБАНК", bankName === "ДОМ.РФ", bankName === "РОССЕЛЬХОЗБАНК");
            if (bankName === "СБЕРБАНК" || bankName === "ДОМ.РФ" || bankName === "РОССЕЛЬХОЗБАНК") {
                setInsuranceType({
                    ...insuranceType,
                    title: false,
                    comprehensiveInsurance: false
                });
                setShowTitul(false);
            } else {
                setShowTitul(true);
            };

        } else {
            setShowTitul(true);
        };
    }, [bankBeneficiary]);

    //useEffect(() => {
    //    console.log("bankBeneficiary",bankBeneficiary)
    //}, [bankBeneficiary])

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
            
            try {
                setError(null);
                setIsLoading(true);
                //if (process.env.NODE_ENV !== 'production')
                const loadedBanks = await (
                    await fetch(
                        `api/dictionary/${type}/banks`, {
                            method: "GET",
                            mode: 'cors',
                            //mode: 'no-cors',
                            headers: new Headers({
                                "ngrok-skip-browser-warning": "69420",
                                'Content-Type': 'application/json',
                            })
                        }
                    )
                ).json();

                const _loadedBanks = loadedBanks.map(item => { return { ...item, value: item.id, label: item.name } });
                //console.log("_loadedBanks: ", _loadedBanks);
                setBanks(_loadedBanks);
                //setError("banks downloadded");

            } catch (e) {
                setError(`api/dictionary/${type}/banks` + " - ошибка загрузки - " + e.message + ' - ' + e.stack);
            };

            setIsLoading(false);
        };

        dataFetch();
    }, []);

    const onSendData = useCallback(() => {
        tg.MainButton.showProgress();

        const data = {
            type: type,
            gender,
            newMortgage,
            birthday,
            dateCredit,
            bankBeneficiary,
            insuranceType,
            summ,
            //new data
            objectOfInsuranceType: objectOfInsuranceTypeId,
            yearOfConstruction,
            floorMaterial,
            wallMaterial,
            square: isNaN(parseFloat(square.replace(" ", ""))) ? null : parseFloat(square.replace(" ", ""))
        };

        if (main_menu && clientId) {

            const sendDataToController = async () => {
                //console.log(data);
                //console.log(JSON.stringify(data));

                const resp = await fetch(
                    //`https://1b77-178-168-122-93.ngrok-free.app/api/ticket/${clientId}/new_ticket`, {
                    `api/ticket/${clientId}/new_ticket`, {
                    method: "POST",
                    mode: 'cors',
                    //mode: 'no-cors',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify({ ...data })
                });

                if (resp) console.log(resp);

                tg.close();
            };

            sendDataToController();

        } else {
            tg.sendData(JSON.stringify(data));
        }

    }, [
        main_menu,
        clientId,
        tg,
        gender,
        newMortgage,
        birthday,
        dateCredit,
        bankBeneficiary,
        insuranceType,
        summ,
        objectOfInsuranceTypeId,
        yearOfConstruction,
        floorMaterial,
        wallMaterial,
        square
    ]);

    //useEffect(() => {
    //    console.log(objectOfInsuranceTypeId);

    //}, [objectOfInsuranceTypeId])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [tg, onSendData]);

    useEffect(() => {

        tg.MainButton.setParams({
            text: 'Отправить заявку'
        });
        tg.MainButton.show();
    }, [tg.MainButton])

    useEffect(() => {
        let typeSelected = insuranceType.property || insuranceType.life || insuranceType.title || insuranceType.comprehensiveInsurance;

        //console.log({
        //    type: type,
        //    gender,
        //    newMortgage,
        //    birthday,
        //    dateCredit,
        //    bankBeneficiary,
        //    insuranceType,
        //    summ,
        //    //new data
        //    objectOfInsuranceType: objectOfInsuranceTypeId,
        //    yearOfConstruction,
        //    floorMaterial,
        //    wallMaterial,
        //    square: square ? (isNaN(parseFloat(square?.replace(" ", ""))) ? null : parseFloat(square?.replace(" ", ""))) : null
        //});

        const _square = square ? (isNaN(parseFloat(square?.replace(" ", ""))) ? null : parseFloat(square?.replace(" ", ""))) : null;

        //console.log((!birthday || !bankBeneficiary || !typeSelected || summ === 0)
        //    , (insuranceType.property === true && (objectOfInsuranceTypeId === null || yearOfConstruction === 0))
        //    , (insuranceType.property === true && (objectOfInsuranceTypeId === 2 || objectOfInsuranceTypeId === 4) && (yearOfConstruction === 0 || !floorMaterial || !wallMaterial || (!_square || _square <= 0) || objectOfInsuranceTypeId === null)));

        if ((!birthday || !bankBeneficiary || !typeSelected || summ === 0)
            || (insuranceType.property === true && (objectOfInsuranceTypeId === null || yearOfConstruction === 0))
            || (insuranceType.property === true && (objectOfInsuranceTypeId === 2 || objectOfInsuranceTypeId === 4) && (yearOfConstruction === 0 || !floorMaterial || !wallMaterial || (!_square || _square <= 0) || objectOfInsuranceTypeId === null)))
        {
            tg.MainButton.disable();
            tg.MainButton.setParams({
                "color": "#A9A9A9",
                //"textColor": tg.ThemeParams.button_text_color ? tg.ThemeParams.button_text_color : "#FFFFFF"
            });
            //console.log("MainButton.disable()");
        } else {
            tg.MainButton.enable();
            tg.MainButton.setParams({
                "color": tg.ThemeParams?.button_color ? tg.ThemeParams.button_color : "#143F6B",
                //"textColor": tg.ThemeParams.button_text_color ? tg.ThemeParams.button_text_color : "#FFFFFF"
            });
            //console.log("MainButton.enable()");
        }
    }, [tg.ThemeParams, tg.MainButton, gender, newMortgage, birthday, dateCredit, bankBeneficiary, insuranceType, summ, objectOfInsuranceTypeId, yearOfConstruction, floorMaterial, wallMaterial, square]);

    // const onChangeSumm = (value) => {
    //     const s = parseInt(value);
    //     //console.log();
    //     setSumm(isNaN(s) ? 0 : s);
    // };


    const onChangeSumm = (value) => {
        //const s = parseInt(value.replace(/\s/g, ''), 10);
        //setSumm(isNaN(s) ? 0 : s);
        setSumm(value);
    };

    const currencyFormat = (numStr) => {
        if (numStr === '') return '';
        console.log(new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits: 2,
        }).format(numStr));

        return new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits: 2,
        }).format(numStr);
    };

    const onClickInsuranceTypeHandler = (type) => {
        setInsuranceType(state => {
            let newState = { ...state };
            if (type === "comprehensiveInsurance") {
                if (state[type] === true) {
                    newState.property = false;
                    newState.life = false;
                    newState.title = false;
                    newState.comprehensiveInsurance = false;
                } else {
                    newState.property = true;
                    newState.life = true;
                    newState.title = true;
                    newState.comprehensiveInsurance = true;
                }
            } else {
                newState[type] = !newState[type];

                if (type === "property" && newState[type] === false) {
                    newState.title = false;
                    newState.comprehensiveInsurance = false;
                };

                if (newState.property && newState.life && newState.title) {
                    newState.comprehensiveInsurance = true;
                } else {
                    newState.comprehensiveInsurance = false;
                }
            }

            return newState;
        });
    };

    const showPopoverIfNeedHandler = () => {
        //console.log(insuranceType.property === false)
        if (insuranceType.property === false) {
            setShowPopover(true);
        };
    }

    useEffect(() => {
        if (showPopover === true) {
            setTimeout(() => {
                setShowPopover(false);
            }, 1000);
        }
    }, [showPopover]);

    return (<div className="body-tg-form">
        <div className={"form"}>
            <div className='form-tg'>
            {/* <h3>Введите ваши данные</h3> */}
            {/*<div>{getQueryParams().userId}</div>*/}
            {error && <div className='tg-error'>{error}</div>}
            <label className='label-input-tg'>Ипотека</label>
            <div className='type-selector'>
                <div className={'type-item' + (newMortgage ? ' active' : '')} onClick={() => setNewMortgage(true)}>Новая</div>
                <div className={'type-item' + (!newMortgage ? ' active' : '')} onClick={() => setNewMortgage(false)}>Действующая</div>
            </div>

            <label className='label-input-tg'>Пол</label>
            <div className='type-selector'>
                <div className={'type-item' + (gender === "Мужской" ? ' active' : '')} onClick={() => setGender("Мужской")}>Мужской</div>
                <div className={'type-item' + (gender === "Женский" ? ' active' : '')} onClick={() => setGender("Женский")}>Женский</div>
            </div>

                
                <DateInput
                    onChange={(e) => setBirthday(e.target.value)}
                    value={birthday}
                    //nolabel //Если нужно убрать лабел полностью
                    //autodate //Если передать данное свойство то после схода с поля даты если оно пустое заполнится текущая дата
                    label="Дата рождения"
                    placeholder="Дата рождения (01.05.2017)"
                    //className="Новые классы если надо их заменить"
                    //onlyyear // Если ввод только года
                    //monthandyaer // Если вводится месяц и год
                />


            <div className='input-selector' onClick={() => { }} >
            <label className='label-input-tg'>Банк</label>
                <Select name="bank" id="bank"
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                            boxShadow: state.isFocused ? 'none' : null,
                        }),
                    }}
                    value={banks.find((bank) => bank.id === bankBeneficiary)}
                    placeholder="Выберете банк"
                    onChange={(val) => {
                        setBankBeneficiary(parseInt(val.id))
                    }}
                    options={banks}
                />
                    
                
            </div>

            {/* <div className='block-header'>Включенные в полис Риски</div> */}
            <label className='label-input-tg'>Включенные в полис Риски</label>
            <div className='availability-select'>
                <ToggleSwitch
                    value={insuranceType.property}
                    onChange={() => onClickInsuranceTypeHandler("property")}
                    name="Имущество"
                />
            </div>
                {insuranceType.property && (
                    <>
                        <label className='label-input-tg' htmlFor='objectOfInsuranceType'>Объект страхования</label>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                    boxShadow: state.isFocused ? 'none' : null,
                                }),
                            }}
                            name="objectOfInsuranceType" id="objectOfInsuranceType"
                            value={objectOfInsuranceTypes.find(e => e.value === objectOfInsuranceTypeId)}
                            onChange={(e) => {
                                setWallMaterial(null);
                                setFloorMaterial(null);
                                setSquare(0.0);
                                setYearOfConstruction(0);
                                setObjectOfInsuranceTypeId(e.value);
                            }}
                            options={objectOfInsuranceTypes}
                            placeholder=" -- Выбрать объект страхования -- "
                            isSearchable={false}
                        />

                        <DateInput
                            onChange={(e) => setYearOfConstruction(state => {
                                let v = parseInt(e.target.value);

                                if (isNaN(v)) v = 0;

                                return v;
                            })}
                            value={yearOfConstruction === 0 ? "" : yearOfConstruction}
                            label="Год постройки"
                            placeholder="Год постройки (1990)"
                            onlyyear
                        />

                        {(objectOfInsuranceTypeId === 2 || objectOfInsuranceTypeId === 4) && (
                            <>
                                <label className='label-input-tg'>Площадь</label>
                                <NumericFormat
                                    className={'masked-input'}
                                    //type="number"
                                    placeholder="Площадь"
                                    value={square <= 0 ? "" : square}
                                    onChange={(e) => setSquare(e.target.value)}
                                    thousandSeparator=" "
                                    decimalScale={2}
                                />
                                

                                <div className='input-selector' onClick={() => { }} >
                                    <label className='label-input-tg' htmlFor='floorMaterial'>Материал перекрытий</label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                                boxShadow: state.isFocused ? 'none' : null,
                                            }),
                                        }}
                                        name="floorMaterial" id="floorMaterial"
                                        value={materialOptions.find(e => e.value === floorMaterial)}
                                        onChange={(e) => setFloorMaterial(e.value)}
                                        options={materialOptions}
                                        placeholder=" -- Выбрать материал -- "
                                        isSearchable={false}
                                    />
                                </div>
                                <div className='input-selector' onClick={() => { }} >
                                    <label className='label-input-tg' htmlFor='wallMaterial'>Материал стен</label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                                boxShadow: state.isFocused ? 'none' : null,
                                            }),
                                        }}
                                        name="wallMaterial" id="wallMaterial"
                                        value={materialOptions.find(e => e.value === wallMaterial)}
                                        onChange={(e) => setWallMaterial(e.value)}
                                        options={materialOptions}
                                        placeholder=" -- Выбрать материал -- "
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            <div className='availability-select'>
                    <ToggleSwitch
                    value={insuranceType.life}
                    onChange={() => onClickInsuranceTypeHandler("life")}
                    name="Жизнь"
                    />
                
                </div>
                {showTitul &&
                    <>
                        <div className='availability-select' onClick={showPopoverIfNeedHandler}>
                            <ToggleSwitch
                            value={insuranceType.title}
                            onChange={() => onClickInsuranceTypeHandler("title")}
                            name="Титул"
                            disabled={!insuranceType.property}
                            />
                        </div>
                        <div className='availability-select'>
                            <ToggleSwitch
                                value={insuranceType.comprehensiveInsurance}
                                onChange={() => onClickInsuranceTypeHandler("comprehensiveInsurance")}
                                name="Комплексное страхование"
                                //disabled={!insuranceType.property}
                            />
                        </div>
                    </>
                }

                <DateInput
                    onChange={(e) => setDateCredit(e.target.value)}
                    value={dateCredit}
                    //nolabel //Если нужно убрать лабел полностью
                    label="Дата выдачи кредита"
                    placeholder="Дата выдачи кредита (06.09.2020)"
                    //className="Новые классы если надо их заменить"
                />

                <label className='label-input-tg'>{newMortgage ? "Сумма кредита" : "Остаток долга по кредиту" }</label>
                {/* <Input
                    className={'input'}
                    type="text"
                    placeholder={newMortgage ? "Сумма кредита" : "Остаток основного долга по кредиту"}
                    value={summ === 0 ? "" : summ.toLocaleString('ru-RU')}
                    onChange={(e) => onChangeSumm(e.target.value)}
                    tgForm={true}
                /> */}
                <NumericFormat
                    className={'masked-input'}
                    type="text"
                    placeholder={newMortgage ? "Сумма кредита" : "Остаток основного долга по кредиту"}
                    value={summ === 0 ? "" : summ}
                    onChange={(e) => onChangeSumm(e.target.value)}
                    thousandSeparator=" "
                    decimalScale={2}
                    //format={currencyFormat}
                />

            </div>
        </div>
            {showPopover && <Popover message="Страхование титула невозможно без страхования имущества" />}
        </div>
    );
};

const IpotekaNewPolisData = () => {

    const { onToggleButton, tg } = useTelegram();

    useEffect(() => {
        tg.ready();
    }, [tg]);

    return (
        <div className="tg-header">
            <TgHeader name="Заявка" type="Ипотека" />
            <NewIpotekaForm />
        </div>
    );
};

const Popover = ({ message }) => {
    return (
        <div className="custom-popover">
            <div className="popover-text">
                {message}
            </div>
        </div>
    );
};

export default IpotekaNewPolisData;