import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { MdDelete, MdAdd } from 'react-icons/md';
import { Icon } from '../../components/UI';
import Select from 'react-select';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";  // добавил datepicker
import "react-datepicker/dist/react-datepicker.css";                       // // добавил datepicker
import { useTelegram } from "../../hooks/useTelegram";
//import { useTelegramBackRoutes } from '../../hooks/useTelegramBack';

import { Input } from '../../components/UI';
import TgHeader from '../../components/TgHeader';

import './tg.css';
import CheckBox from '../../components/UI/CheckBox/CheckBox';

import { fetchRegions, getVehicleMakers, getVehicleModels, getVehicleTypes } from '../../store/dictionarySlice';

// const regions = [

// ];

const TgButton = (props) => {
    return (
        <button {...props} className={'button ' + props.className} />
    );
};

const NewKaskoForm = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const previousPage = location.state?.previousPage;

    //main_menu userId
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const main_menu = searchParams.get("main_menu");
    const clientId = searchParams.get("userId");
    const regions = useSelector(store => store.dictionaries.regions).map(item => { return { ...item, value: item.id, label: item.name } });
    const vehicleTypes = (useSelector(store => store.dictionaries.vehicleTypes).map(item => { return { value: item.id, label: item.name } }));
    const vehicleMakers = (useSelector(store => store.dictionaries.vehicleMakers).map(item => { return { value: item.id, label: item.name } }));
    const vehicleModels = (useSelector(store => store.dictionaries.vehicleModels).map(item => { return { value: item.id, label: item.name } }));

    //console.log("BTN DATA 0 ", main_menu, clientId);
    const maxId = useRef(0);
    const type = "Каско";
    const [banks, setBanks] = useState([]);
    const [region, setRegion] = useState("");
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [fio, setFio] = useState('');
    const [birthday, setBirthday] = useState('');
    const [passport, setPassport] = useState('');
    const [iDriver, setIDriver] = useState(false);
    const [creditCar, setcreditCar] = useState(false);
    const [bankBeneficiary, setBankBeneficiary] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [vehicleType, setVehicleType] = useState();
    const [vehicleMaker, setVehicleMaker] = useState();
    const [vehicleModel, setVehicleModel] = useState();
    //const [driverFio, setDriverFio] = useState('');
    //const [driverBirthday, setDriverBirthday] = useState('');
    //const [driverPassport, setDriverPassport] = useState('');
    //const [years, setYears] = useState('');
    // const [region, setRegion] = useState('Москва');

    const [vin, setVin] = useState('');
    const [stspts, setStspts] = useState('');
    const [model, setModel] = useState('');
    const [horsePower, sethorsePower] = useState('');
    const [summ, setSumm] = useState('');
    const [year, setYear] = useState('');

    const [error, setError] = useState(null);

    const { tg } = useTelegram();
    //console.log("ready ", window.Telegram.WebApp.ready());

    const driverInitialState = {
        driverId: 0,
        driverFio: "",
        driverBirthday: "",
        driverPassport: "",
        years: ""
    };

    //console.log({
    //    type: "Каско",
    //    fio,
    //    birthday,
    //    passport,
    //    drivers,
    //    creditCar,
    //    bankBeneficiary,
    //    vin,
    //    stspts,
    //    model,
    //    horsePower,
    //    year,
    //    region,
    //    selectedRegion,
    //    summ
    //});

    useEffect(() => {
        if (selectedRegion && selectedRegion !== null && selectedRegion !== 0)
            setRegion(regions.find((region) => region.id === selectedRegion)?.name);
        else
            setRegion("");
    }, [selectedRegion, regions]);

    const onSendData = useCallback(() => {
        try {
            console.log("BTN DATA ", main_menu, clientId);
            tg.MainButton.showProgress();
            //window.Telegram.WebApp.showProgress();
            const data = {
                type: "Каско",
                fio,
                birthday,
                passport,
                drivers,
                creditCar,
                bankBeneficiary,
                vin,
                stspts,
                model,
                horsePower,
                year,
                region,
                selectedRegion,
                summ,
                //Вид транспорта
                vehicleType: vehicleType.toString(),
                //Производитель транспорта
                vehicleMaker: vehicleMaker.toString(),
                //Модель транспортного средства
                vehicleModel: vehicleModel.toString()
            };
            console.log("BTN DATA ", main_menu, clientId);

            if (main_menu && clientId) {

                const sendDataToController = async () => {
                    console.log(data);
                    console.log(JSON.stringify(data));

                    const resp = await fetch(
                        //`https://1b77-178-168-122-93.ngrok-free.app/api/ticket/${clientId}/new_ticket`, {
                        `api/ticket/${clientId}/new_ticket`, {
                        method: "POST",
                        mode: 'cors',
                        //mode: 'no-cors',
                        headers: new Headers({
                            "ngrok-skip-browser-warning": "69420",
                            'Content-Type': 'application/json',
                        }),
                        body: JSON.stringify({ ...data })
                    });

                    if (resp) console.log(resp);

                    tg.close();
                };

                sendDataToController();

            } else {
                //console.log(JSON.stringify(data));
                tg.sendData(JSON.stringify(data));
            }
        } catch (e) {
            console.log(e);
            //setError(e.toString())
        }
    }, [main_menu, vehicleType, vehicleMaker, vehicleModel, clientId, tg.MainButton, tg, fio, birthday, passport, creditCar, bankBeneficiary, drivers, vin, stspts, model, horsePower, year, region, summ]);



    /// банки
    useEffect(() => {
        // fetch data
        const dataFetch = async () => {

            try {
                setError(null);
                setIsLoading(true);
                //if (process.env.NODE_ENV !== 'production')
                const loadedBanks = await (
                    await fetch(
                        `api/dictionary/${type}/banks`, {
                        method: "GET",
                        mode: 'cors',
                        //mode: 'no-cors',
                        headers: new Headers({
                            "ngrok-skip-browser-warning": "69420",
                            'Content-Type': 'application/json',
                        })
                    }
                    )
                ).json();

                const _loadedBanks = loadedBanks.map(item => { return { ...item, value: item.id, label: item.name } });
                //console.log("_loadedBanks: ", _loadedBanks);
                setBanks(_loadedBanks);
                //setError("banks downloadded");

            } catch (e) {
                setError(`api/dictionary/${type}/banks` + " - ошибка загрузки - " + e.message + ' - ' + e.stack);
            };

            setIsLoading(false);
        };

        dataFetch();
    }, []);


    /// регионы + транспорт
    useEffect(() => {
        dispatch(fetchRegions());
        dispatch(getVehicleTypes());
    }, []);

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        //tg.onEvent('settingsButtonClicked', () => { console.log("settings button") })
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
            //tg.offEvent('settingsButtonClicked', () => { console.log("settings button off") })
        }
    }, [tg, onSendData]);

    //const onClickBackButtonHandler = () => {
    //    tg.MainButton.hide();
    //    navigate(-1);
    //    //if (previousPage)
    //    //    navigate(previousPage, { state: {type: "kasko"}});
    //};

    //useEffect(() => {
    //    tg.ready();
    //    //history.goBack();
    //    //if (insuranceType || insuranceType === 0) {

    //    //} else {
    //    if (previousPage) {
    //        tg.BackButton.show();

    //        tg.onEvent('backButtonClicked', onClickBackButtonHandler);
    //        return () => {
    //            tg.offEvent('backButtonClicked')
    //        }
    //    }
    //    //}

    //}, [tg, previousPage]);

    useEffect(() => {

        tg.MainButton.setParams({
            text: 'Отправить заявку'
        });
        tg.MainButton.show();
        //tg.MainButton.onClick(onSendData)
        //return () => {
        //    //Telegram.WebApp.MainButton.hide();
        //    tg.MainButton.offClick(onSendData);
        //};
    }, [tg.MainButton])

    useEffect(() => {
        if (!vehicleMaker || !vehicleModel || !vehicleType || !birthday || !fio || !passport || !vin || !stspts || !horsePower || !year || !region || !summ || drivers.length === 0) {
            tg.MainButton.disable();
            tg.MainButton.setParams({
                "color": "#A9A9A9",
                //"textColor": tg.ThemeParams.button_text_color ? tg.ThemeParams.button_text_color : "#FFFFFF"
            });
        } else {
            tg.MainButton.enable();
            tg.MainButton.setParams({
                "color": tg.ThemeParams?.button_color ? tg.ThemeParams.button_color : "#143F6B",
                //"textColor": tg.ThemeParams.button_text_color ? tg.ThemeParams.button_text_color : "#FFFFFF"
            });
        }
    }, [tg.ThemeParams, vehicleMaker, vehicleModel, vehicleType, tg.MainButton, fio, birthday, bankBeneficiary, passport, selectedRegion, creditCar, drivers, vin, stspts, model, horsePower, year, region, summ]);

    const onChangeFio = (e) => {
        setFio(e.target.value.toUpperCase())
    }

    const onChangeBirthday = (e) => {
        setBirthday(e.target.value)
    }

    // const onChangePassport = (e) => {
    //     setPassport(e.target.value)
    // }

    console.log(vehicleType);

    const onChangePassport = (e) => {
        let inputValue = e.target.value.replace(/\s/g, '');
        if (inputValue.length > 4) {
            inputValue = inputValue.slice(0, 4) + ' ' + inputValue.slice(4);
        }
        setPassport(inputValue);
    };

    const onChangeDriverFio = (data) => {
        //setDriverFio(e.target.value)
        const { value, driverId } = data;

        const updatedDrivers = [...drivers.map((item) => {
            if (item.driverId === driverId) {
                return { ...drivers.find(e => e.driverId === driverId), driverFio: value }
            } else {
                return item;
            }
        })];

        setDrivers(updatedDrivers);
    }

    const onChangeDriverBirthday = (data) => {
        //setDriverBirthday(e.target.value)

        const { value, driverId } = data;

        const updatedDrivers = [...drivers.map((item) => {
            if (item.driverId === driverId) {
                return { ...drivers.find(e => e.driverId === driverId), driverBirthday: value }
            } else {
                return item;
            }
        })];

        setDrivers(updatedDrivers);
    }

    const onChangeDriverPassport = (data) => {
        //setDriverPassport(e.target.value)

        const { value, driverId } = data;

        const updatedDrivers = [...drivers.map((item) => {
            if (item.driverId === driverId) {
                return { ...drivers.find(e => e.driverId === driverId), driverPassport: value }
            } else {
                return item;
            }
        })];

        setDrivers(updatedDrivers);
    }

    const onChangeYears = (data) => {
        //setYears(e.target.value)
        //console.log(data);
        const { value, driverId } = data;

        let v = parseInt(value);
        if (isNaN(v)) v = "";

        const updatedDrivers = [...drivers.map((item) => {
            if (item.driverId === driverId) {
                //console.log(item);
                return { ...drivers.find(e => e.driverId === driverId), years: v.toString() }
            } else {
                return item;
            }
        })];

        setDrivers(updatedDrivers);
    }

    const onChangeVin = (e) => {
        setVin(e.target.value.toUpperCase())
    }

    const onChangeStspts = (e) => {
        setStspts(e.target.value)
    }

    const onChangeModel = (e) => {
        setModel(e.target.value)
    }

    const onChangehorsePower = (e) => {
        // sethorsePower(e.target.value)
        let v = parseInt(e.target.value);
        if (isNaN(v)) v = "";
        sethorsePower(v.toString());
    }

    // const onChangeRegion = (e) => {
    //     setRegion(e.target.value)
    // }

    const onChangeSumm = (e) => {
        let v = parseInt(e.target.value);
        if (isNaN(v)) v = "";
        setSumm(v.toString());
    };

    const onChangeYear = (e) => {
        setYear(e.target.value)
    }

    //useEffect(() => {
    //    console.log(drivers);
    //}, [drivers]);
    //const onIDriverChangeHandler = useCallback(() => {
    //    if (iDriver) {

    //    } else {
    //        addDriver({
    //            ...driverInitialState,
    //            driverFio: fio,
    //            driverBirthday: birthday,
    //        });
    //    };
    //    console.log(drivers);
    //    setIDriver(!iDriver);
    //}, [fio, birthday, iDriver]);

    const onIDriverChangeHandler = () => {
        if (iDriver) {
            const delDriver = drivers.find(item => item.driverFio === fio && item.driverBirthday === birthday);
            deleteDriver(delDriver.driverId);
        } else {
            addDriver({
                ...driverInitialState,
                driverFio: fio,
                driverBirthday: birthday,
            });
        };
        setIDriver(!iDriver);
    };

    const oncreditCar = () => {
        setcreditCar(!creditCar);
        console.log("Credit car  " + !creditCar)
    }
    const addDriver = (driverData = null) => {
        maxId.current += 1;
        const newDriver = driverData ? driverData : driverInitialState;
        //console.log('newDriver', newDriver);
        setDrivers(state => [...state, { ...newDriver, driverId: maxId.current }]);
    };

    const deleteDriver = (id) => {
        const delDriver = drivers.find(item => item.driverId);
        if (fio === delDriver.driverFio && birthday === delDriver.driverBirthday) {
            setIDriver(false);
        };

        setDrivers(state => [...state.filter(item => item.driverId !== id)]);
    };

    //console.log(vahicleTypes);

    return (
        <div className="body-tg-form">
            <>
                <div className={"form"}>
                    <div className='form-tg'>
                        {/* <h3>Введите ваши данные</h3> */}
                        <div className="block-header">
                            Владелец транспортного средства
                        </div>
                        <label className='label-input-tg'>ФИО</label>
                        <Input
                            className={'input'}
                            type="text"
                            placeholder={'Введите ФИО'}
                            value={fio}
                            onChange={onChangeFio}
                            tgForm={true} />
                        <label className='label-input-tg'>Дата рождения</label>
                        <MaskedInput
                            mask={[/[0-3]/, /[0-9]/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/]}
                            className={'masked-input'}
                            type="text"
                            placeholder={'Выберите дату рождения (01.12.1990)'}
                            value={birthday}
                            onChange={onChangeBirthday} />
                        <label className='label-input-tg'>Серия и номер паспорта</label>
                        <Input
                            className={'input'}
                            type="text"
                            placeholder={'Введите серию и номер паспорта'}
                            value={passport}
                            onChange={onChangePassport}
                            tgForm={true} />
                        {/* <Input
                    className={'input'}
                    type="text"
                    placeholder={'Регион по прописке'}
                    value={region}
                    onChange={onChangeRegion}
                    tgForm={true} /> */}
                    <label className='label-input-tg'>Город</label>
                        <Select
                            name="region"
                            id="region"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                    boxShadow: state.isFocused ? 'none' : null,
                                    marginBottom: "8px",
                                }),
                            }}
                            value={regions.find((region) => region.id === selectedRegion)}
                            placeholder="Выберете регион"
                            onChange={(val) => {
                                setSelectedRegion(parseInt(val.id))
                            }}
                            options={regions}
                        />
                        <div className='input-checkbox'>
                            <CheckBox
                                id="iDriver"
                                name="Буду за рулем"
                                value={iDriver}
                                onChange={onIDriverChangeHandler}
                                tgForm={true} />
                        </div>
                    </div>
                </div>
                <div className="form-transport">
                    <div className="block-header">
                        Транспортное средство
                    </div>
                    {/* <Input
                    className={'input'}
                    type="text"
                    placeholder={'Марка и модель ТС'}
                    value={model}
                    onChange={onChangeModel}
                    tgForm={true} /> */}
                    <label className='label-input-tg'>Вид ТС</label>
                    <Select
                        required
                        name="vehicleType"
                        id="vehicleType"
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                boxShadow: state.isFocused ? 'none' : null,
                                marginBottom: "8px",
                            }),
                        }}
                        value={vehicleType ? vehicleTypes.find((type) => type.id === vehicleType) : null}
                        placeholder="Выберете вид транспорта"
                        onChange={(item) => {

                            setVehicleMaker(null);
                            setVehicleModel(null);
                            dispatch(getVehicleMakers(item.value));
                            setVehicleType(parseInt(item.value));

                        }}
                        options={vehicleTypes}
                    />
                    <label className='label-input-tg'>Марка ТС</label>
                    <Select
                        name="vehicleMaker"
                        id="vehicleMaker"
                        isDisabled={vehicleType ? false : true}
                        required
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                boxShadow: state.isFocused ? 'none' : null,
                                marginBottom: "8px",
                            }),
                        }}
                        value={vehicleMaker ? vehicleMakers.find((item) => item.id === vehicleMaker) : null}
                        placeholder="Выберете марку"
                        onChange={(item) => {
                            setVehicleModel(null);
                            dispatch(getVehicleModels({ type: vehicleType, maker: parseInt(item.value) }))
                            setVehicleMaker(parseInt(item.value))
                        }}
                        options={vehicleMakers}
                    />
                    <label className='label-input-tg'>Модель ТС</label>
                    <Select
                        name="vehicleModel"
                        id="vehicleModel"
                        isDisabled={vehicleMaker ? false : true}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                boxShadow: state.isFocused ? 'none' : null,
                                marginBottom: "8px",
                            }),
                        }}
                        value={vehicleModels.find((model) => model.id === vehicleModel)}
                        placeholder="Выберете модель"
                        onChange={(item) => {
                            setVehicleModel(parseInt(item.value));
                        }}
                        options={vehicleModels}
                    />
                    <label className='label-input-tg'>Мощность ТС</label>
                    <Input
                        className={'input'}
                        type="text"
                        placeholder={'Мощность'}
                        value={horsePower}
                        onChange={onChangehorsePower}
                        tgForm={true} />
                    <label className='label-input-tg'>Год выпуска ТС</label>
                    <MaskedInput
                        className={'masked-input'}
                        mask={[/[1-2]/, /\d/, /\d/, /\d/]}
                        type="text"
                        placeholder={'Год выпуска'}
                        value={year}
                        onChange={onChangeYear}
                        tgForm={true} />
                    <label className='label-input-tg'>VIN номер</label>
                    <Input
                        className={'input'}
                        type="text"
                        placeholder={'Введите VIN номер'}
                        value={vin}
                        onChange={onChangeVin}
                        tgForm={true} />
                    <label className='label-input-tg'>Номер СТС и ПТС</label>
                    <Input
                        className={'input'}
                        type="text"
                        placeholder={'Введите номер СТС и ПТС'}
                        value={stspts}
                        onChange={onChangeStspts}
                        tgForm={true} />
                    <label className='label-input-tg'>Цена ТС</label>
                    <Input
                        className={'input'}
                        type="text"
                        placeholder={'Оценочная стоимость ТС'}
                        value={summ}
                        onChange={onChangeSumm}
                        tgForm={true} />
                <div className='input-checkbox-container-tg'>
                    <CheckBox
                        id="creditCar"
                        name="ТС в кредите"
                        value={creditCar}
                        onChange={oncreditCar}
                        tgForm={true} />
                </div>
                    {creditCar && ( // Проверяем, что галочка тс в кредит включена если да то список банков
                        <>
                        <div className='container-select-bank-tg'>
                        <label className='label-input-tg'>Выберете банк</label>
                            <Select
                                name="bank"
                                id="bank"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#50A7EA' : '#DDE2E4',
                                        boxShadow: state.isFocused ? 'none' : null,
                                        marginBottom: "8px",
                                    }),
                                }}
                                value={banks.find((bank) => bank.id === bankBeneficiary)}
                                placeholder="Выберете банк"
                                onChange={(val) => {
                                    setBankBeneficiary(parseInt(val.id))
                                }}
                                options={banks}
                            />
                            </div>
                        </>
                    )}
                </div>
                <div className="form-drivers">
                    <div >
                        <div className="block-header">
                            Водители {/*({drivers.length}):*/}
                        </div>

                        {drivers.map((item, index) => {
                            const { driverFio, driverBirthday, driverPassport, years, driverId } = item;
                            //console.log(years);
                            const disabled = fio === driverFio && birthday === driverBirthday;

                            return (
                                <div key={driverId} className='driver-info-card'>
                                    <div className='driver-info-ID'>
                                        <div className="number-drivers">Водитель №{index + 1}</div>
                                    </div>
                                    {disabled === false && <div className='driver-info-del' onClick={() => deleteDriver(driverId)}><MdDelete color='red' size={24} /></div>}
                                    <label className='label-input-tg'>ФИО</label>
                                    <Input
                                        className={'input'}
                                        type="text"
                                        disabled={disabled}
                                        placeholder={'Введите ФИО'}
                                        value={driverFio}
                                        onChange={e => onChangeDriverFio({ value: e.target.value, driverId: driverId })}
                                        tgForm={true} />
                                    <label className='label-input-tg'>Дата рождения</label>
                                    <MaskedInput
                                        mask={[/[0-3]/, /[0-9]/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/]}
                                        className={'masked-input'}
                                        type="text"
                                        disabled={disabled}
                                        placeholder={'Выберите дату рождения(01.12.1990)'}
                                        value={driverBirthday}
                                        onChange={(e) => onChangeDriverBirthday({ value: e.target.value, driverId })} />
                                    <label className='label-input-tg'>Серия, № ВУ</label>
                                    <Input
                                        className={'input'}
                                        type="text"
                                        placeholder={'Введите серии и № ВУ'}
                                        value={driverPassport}
                                        onChange={(e) => onChangeDriverPassport({ value: e.target.value, driverId })}
                                        tgForm={true} />
                                    <label className='label-input-tg'>Стаж вождения</label>
                                    <Input
                                        className={'input'}
                                        type="text"
                                        placeholder={'Стаж вождения (лет)'}
                                        value={years}
                                        onChange={(e) => onChangeYears({ value: e.target.value, driverId })}
                                        tgForm={true} />


                                        {index < drivers.length - 1 && 
                                            <div className='line-outside-tg'></div>
                                        }

                                </div>
                                
                            );
                        })}
                        <div className='text-button'>
                            <div className="adddrivertext" onClick={() => addDriver()}> <Icon name='tgaddbutton' /> Добавить водителя</div>
                        </div>
                    </div>


                    {/*{error && <>{error}</>}*/}
                    {/*<div onClick={onSendData} >send</div>*/}
                </div>
            </>
        </div>
    );
};

const KaskoNewPolisData = () => {

    const { onToggleButton, tg } = useTelegram();

    useEffect(() => {
        tg.ready();
    }, [tg]);

    return (<div className="tg-header">
        <>
            <TgHeader name="Заявка" type="Каско" />
            <NewKaskoForm />
        </>
    </div>
    );
};

export default KaskoNewPolisData;