import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { NumericFormat, NumberFormatBase } from 'react-number-format';
import { useParams } from 'react-router';
import { useTelegram } from "../../hooks/useTelegram";
import TgHeader from '../../components/TgHeader';
import { requestTypeName, requestTypeNameMenu } from '../../additional';
import Upload from '../../components/Upload/Upload';
import { ButtonUpload, Popover } from './MainMenu';
import { Icon } from '../../components/UI';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import ClipLoader from "react-spinners/ClipLoader";

const UploadFiles = () => {
    const { height, width } = useWindowDimensions();
    const { tg } = useTelegram();
    const selectFilesRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [files, setFiles] = useState([]);
    const params = useParams();
    const requestTypeName = params?.type;
    const tg_user_id = params?.tg_user_id;
    const [error, setError] = useState(null);
    const [summ, setSumm] = useState(null);

    const [acceptProcessingPersonalData, setAcceptProcessingPersonalData] = useState(false);

    //// TODO добавить режим подгрузки файлов к тикету чтоб переиспользовать данную форму
    //const ticketId = params?.ticketId;
    ////Вариант для работы формы
    ////create_ticket - для создания тикета
    ////upload_to_ticket - для подгрузки к тикету
    //const upload_mode = params?.upload_mode;

    const handleFileChange = ({ target }) => {
        if (target.files) {
            //setStatus('initial');
            setIsUploading(true);
            //setFiles(target.files);
            handleUpload(target.files)
                .then((response) => {
                    console.log(response);
                    setFiles([...files, ...response]);
                    setIsUploading(false);
                    //navigate(`/tg/${tg_user_id}/${navigationData.link}/new/uploadForm`, { state: { previousPage: "/tg/MainMenu", type: "kasko" } });
                    ///tg/:type/new/uploadForm
                })
                .catch((e) => {
                    console.log(e);
                    setIsUploading(false);
                });
        }
    };

    const deleteFileHandler = (fileId) => {
        const deleteTempFile = async () => {
            const tempFiles = await (
                await fetch(
                    `api/additional/DeleteUploadedTempFile/${tg_user_id}/${fileId}`, {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "69420",
                    }
                }
                )
            );

            // set state when the data received
            setFiles(files.filter((file) => file.id !== fileId));
        };
        setDeleting(true);
        deleteTempFile()
            .then(() => {
                
            })
            .finally(() => {
                setDeleting(false);
            });
    };

    const onFinishHandler = () => {
        const createTicketFromFiles = async () => {
            const formData = new FormData();
            formData.append('requestTypeId', requestTypeNameMenu(requestTypeName));
            formData.append('Summ', summ);
            formData.append('AcceptProcessingPersonalData', acceptProcessingPersonalData);

            var result = await fetch(
                `api/additional/CreateTicketFromTempFiles/${requestTypeNameMenu(requestTypeName)}/${tg_user_id}`, {
                    method: 'POST',
                    headers: {
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: formData
                }
            )
        };

        setIsUploading(true);
        setError(null);
        createTicketFromFiles()
            .then(result => {
                tg.close();
            })
            .catch(error => {
                setError("Упс, произошла ошибка, попробуйте позьже");
            })
            .finally(() => {
                setIsUploading(false);
            });
    };

    useEffect(() => {
        //Получим список уже загруженных файлов
        const formData = new FormData();
        formData.append('requestTypeId', requestTypeNameMenu(requestTypeName));

        const getTempFiles = async () => {
            const tempFiles = await (

                await fetch(
                    `api/additional/GetUploadedTempFiles/${tg_user_id}`, {
                    method: 'POST',
                    headers: {
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: formData
                }
                )
            ).json();

            // set state when the data received
            setFiles(tempFiles);
        };

        getTempFiles();

    }, []);

    //useEffect(() => {
    //    if (selectFilesRef.current) {
    //        selectFilesRef.current.click();
    //    };
    //}, [selectFilesRef]);

    const handleUpload = (files, options) => {
        if (files) {
            //setStatus('uploading');

            // Вытащили xhr из Promise, чтобы прокинуть abort
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'json';

            const onProgress = options?.onProgress;

            const promise = new Promise((resolve, reject) => {
                xhr.open('POST', "/api/additional/UploadTempFiles");

                xhr.upload.onprogress = (event) => {
                    onProgress?.(Math.round((event.loaded / event.total) * 100));
                };

                xhr.onload = () => {
                    if (xhr.status === 200) {
                        //console.log(xhr.response);
                        resolve(xhr.response);
                    }
                    else reject(xhr.response);
                };

                const formData = new FormData();
                [...files].forEach((file) => {
                    //console.log(file);
                    formData.append('files', file);
                });
                formData.append('UserId', tg_user_id);
                formData.append('requestTypeId', requestTypeNameMenu(requestTypeName));
                
                xhr.send(formData);
            });

            // Присвоили свойство abort, которое прервет запрос
            promise.abort = () => xhr.abort();

            return promise;

        }
    };


    return (
        <div className='tg-background' style={{ minHeight: height }}>
            <input hidden ref={selectFilesRef} id="file" type="file" multiple onChange={handleFileChange} />
            <TgHeader name="Документы" type={requestTypeNameMenu(requestTypeName)} />
            {isUploading && <Popover message="Дождитесь отправки файлов" />}

            <div className="upload-files-container">
                <div style={{ display: "flex", flexDirection: "column", gap: 12 } }>
                    <div className='referal-link-container'>
                        <div className='icon-text-file-tg'> <Icon name='info-circle' /> Загрузите сюда ваш предыдущий полис</div>
                        <div className='text-info-upload-tg'>Для этого сделайте фотографии всех страниц, либо выберите из галереи</div>
                    </div>
                    <TransitionGroup className="upload-files-container-block">
                        {files &&
                            [...files].map((file, index) => {
                                const nodeRef = createRef(null);

                                return (
                                    <CSSTransition
                                        key={file.id}
                                        nodeRef={nodeRef}
                                        timeout={500}
                                        classNames="item"
                                    >
                                        <div className='container-upload-file-tg' key={file.id} ref={nodeRef}>
                                            <div className='icon-file-upload-tg'>
                                                <img style={{ width: 120, height: 120, border: "1px solid gray", borderRadius: 16 }} src={"api/additional/file/" + file.fileName} alt="" />
                                            </div>
                                            <div className='container-info-img-tg' key={file.name}>
                                                <div>Документ {index + 1}</div>
                                                {/*<div>{file.size} байт</div>*/}
                                            </div>
                                            {!isUploading && (deleting
                                                ?
                                                <ClipLoader
                                                    className="button_spinner"
                                                    color="#50A7EA"
                                                    loading={isUploading}
                                                    size={28}
                                                />
                                                :
                                                <div onClick={() => deleteFileHandler(file.id)}><Icon name='delete' /></div>
                                            )}
                                        </div>
                                    </CSSTransition>
                                )
                            })
                        }

                    </TransitionGroup>

                </div>

                <div className="upload-files-container-block">
                    {(requestTypeName === "ipoteka" || requestTypeName === "kasko") &&
                        <div>
                            <label className='label-input-tg'>{requestTypeName === "ipoteka" ? "Остаток основного долга по кредиту" : "Страховая сумма (Цена ТС)"}</label>
                            <NumericFormat
                                className={'masked-input'}
                                type="text"
                                placeholder={requestTypeName ==="ipoteka" ? "Остаток основного долга по кредиту" : "Страховая сумма (Цена ТС)"}
                                value={summ}
                                onChange={(e) => setSumm(e.target.value)}
                                thousandSeparator=" "
                                decimalScale={requestTypeName === "ipoteka" ? 2 : 0}
                                style={{marginBottom: 0}}
                            />
                        </div>
                    }
                    <div className='personal-data-question'>
                        Я даю согласие на сбор, использование и обработку моих персональных данных и сведений из удостоверения личности в соответствии с Примечанием о конфиденциальности
                    </div>
                    <ButtonUpload leftIconName="attachment" buttonType="tg-white" name="Добавить файл(ы)" isUploading={isUploading} onClick={() => {
                        //tg.close();
                        if (selectFilesRef.current) {
                            selectFilesRef.current.click();
                        };
                    }} />


                    <ButtonUpload
                        buttonType="tg-blue"
                        name="Отправить"
                        disabled={isUploading || files.length === 0}
                        onClick={onFinishHandler}
                    />
                </div>

            </div>
        </div>
    );
};

export default UploadFiles;
